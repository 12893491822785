<template>
  <div v-if="companyData.length != 0">
    <closed v-if="onDuty != 'open'" />
    <orderView v-if="inStore" />
  </div>
</template>

<script>
import orderView from "@/components/orderView.vue";
import closed from "@/components/closed.vue";
import { mapGetters } from "vuex";
import Vue from "vue";
import DataService from "@/services/DataService";

export default {
  name: "page-order",
  components: {
    orderView,
    closed,
  },
  computed: {
    ...mapGetters(["companyData"]),
    onDuty() {
      return this.companyData.onDuty;
    },
    inStore() {
      const inStore = localStorage.getItem("company");
      if (inStore && inStore != 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeMount() {
    var payload;
    if (this.$route.query.apikey) {
      payload = {
        apikey: this.$route.query.apikey,
        allow_stores: false,
      };
      this.$store.dispatch("setStore", payload).then(() => {
        window.location.href = "/";
      });
    } else if (this.inStore) {
      payload = {
        apikey: this.companyData.apiKey,
        allow_stores: true,
      };
      this.$store.dispatch("setStore", payload);
    } else {
      payload = {
        apikey: this.$root.compKey,
        allow_stores: true,
      };
      this.$store.dispatch("setStore", payload);
    }
    if (this.$route.query.lang) {
      Vue.i18n.set(this.$route.query.lang);
    }else{
      Vue.i18n.set(this.$root.default_lang);
    }
  },


};
</script>
