<template>
  <b-navbar toggleable="xl" type="dark" sticky>
    <b-navbar-toggle id="navi" target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <!-- Left aligned nav items -->
      <b-navbar-nav class="mr-auto">
        <b-nav-item-dropdown left v-if="!$route.meta.hideLogin">
          <template slot="button-content">
            {{ $t("Einstellungen") }}
          </template>
          <div v-if="loggedIn">
            <b-dropdown-item to="/account">
              {{ $t("Konto") }}
            </b-dropdown-item>
            <b-dropdown-item to="/orders">
              {{ $t("Bestellungen") }}
            </b-dropdown-item>
          </div>
          <b-dropdown-item to="/">
            {{ companyData.companyName }}
            <b-dropdown-item to="/store">{{ $t("Über Uns") }}</b-dropdown-item>
            <b-dropdown-item to="/legal">{{ $t("Impressum") }}</b-dropdown-item>
            <b-dropdown-item to="/privacy">{{ $t("Datenschutz") }}</b-dropdown-item>
            <b-dropdown-item to="/agb">{{ $t("AGB") }}</b-dropdown-item>
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item v-if="!loggedIn" to="/login">{{ $t("Login") }}</b-dropdown-item>
          <div v-else>
            <b-dropdown-item @click="logout">{{ $t("Logout") }}</b-dropdown-item>
          </div>
            
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto" v-if="!$route.meta.hideLogin">
        
      </b-navbar-nav>
    </b-collapse>
    <!-- Right aligned nav items https://apiv1.xenia-pos.net/-->
    <b-navbar-nav class="ml-auto">
      <b-nav-form>
        <span v-for="(item, index) in companyData.languages" :key="index">
          <img
            v-if="item != '' && item != get_lang()"
            :src="'https://apiv1.xenia-pos.net/images/' + item + '.png'"
            @click="set_lang(item)"
            style="width: 30px; cursor: pointer"
          />
        </span>
        <b-nav-item
          v-if="total > 0"
          to="/cart"
          class="d-none d-md-block d-sm-block d-lg-block d-xl-none"
          style="margin-left: 15px"
        >
          <CartWidget />
        </b-nav-item>
      </b-nav-form>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import CartWidget from "@/components/shopping-cart/CartWidget";

export default {
  name: "app-header",
  data() {
    return {
      min_orderValue: 0,
    };
  },
  computed: {
    ...mapGetters(["loggedIn", "companyData", "allowStoreSelection", "total"]),
  },
  components: {
    CartWidget,
  },
  created() {
    this.min_orderValue = this.companyData.minimumOrderValue;
  },
  methods: {
    clearStore() {
      this.$store.dispatch("logout").then(() => {
        window.localStorage.clear();
        window.location.href =
          "/stores/?lang=" + this.companyData.defaultLanguage;
      });
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$store.dispatch("removeAllFromCart").then(() => {
            this.$router.push({ name: "order" });
        });
      });
    },
    get_lang() {
      return Vue.i18n.locale();
    },
    set_lang(value) {
      Vue.i18n.set(value);
    },
  },
};
</script>

<style scoped>
.min_orderValue {
  color: red;
}
.dropdown-item img {
  width: 50px;
  padding: 5px;
}
.submenu {
  position: relative;
  margin-left: 100%;
}
.icon-wrapper {
  color: #ffffff;
}
</style>
